<template>
  <div>
    <div v-if="pageLoading" class="text-center py-5">
      <BaseProgressCircular />
    </div>
    <v-form ref="form" v-model="valid" v-else>
      <v-row>
        <v-col cols="6" sm="4" class="py-0">
          <DateField
            :readonly="readonly"
            disabled
            label="Date"
            :rules="rules.requiredRule"
            v-model="formData.date"
            :append-icon="readonly ? '' : 'mdi-calendar'"
          >
            <template #message>
              <span v-if="schedule.status" class="success--text">
                <strong>
                  This is a scheduled date that is {{ schedule.message }} in the future
                </strong>
              </span>
            </template>
          </DateField>
        </v-col>
        <v-col cols="6" sm="3" class="py-0">
          <v-select
            label="Type"
            :readonly="readonly"
            :rules="rules.requiredRule"
            outlined
            v-model="formData.type"
            :items="types"
            :append-icon="readonly ? '' : 'mdi-menu-down'"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="5" class="py-0">
          <v-select
            :key="selectMenu"
            label="By"
            multiple
            :readonly="readonly"
            :rules="rules.requiredRule"
            outlined
            v-model="formData.name"
            :append-icon="readonly ? '' : 'mdi-menu-down'"
            :items="userList"
          >
            <template #prepend-item>
              <div class="d-flex align-center mx-3">
                <span>By</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="selectMenu = !selectMenu">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" class="py-0 mt-n5">
          <div class="d-flex flex-wrap">
            <v-radio-group
              v-model="formData.issues"
              row
              :readonly="readonly"
              v-if="showIssue(formData.type)"
            >
              <v-radio :value="false" label="No Issues"></v-radio>
              <v-radio :value="true" label="Issues"></v-radio>
            </v-radio-group>
            <v-checkbox
              v-if="formData.issues || formData.type === 'issues'"
              v-model="formData.issuesResolved"
              @change="updateIssueResolvedDate"
              label="Issues are resolved"
            ></v-checkbox>
          </div>
        </v-col>
        <v-col vols="12" v-if="formData.issues || formData.type === 'issues'" class="py-0">
          <v-textarea
            :readonly="readonly"
            rows="3"
            auto-grow
            v-model="formData.issueDescription"
            outlined
            label="Issues"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-textarea
            outlined
            :readonly="readonly"
            auto-grow
            rows="1"
            label="Wildlife & Habitat"
            v-model="formData.wildlifeAndHabitat"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-textarea
            :readonly="readonly"
            outlined
            rows="2"
            auto-grow
            v-model="formData.comments"
            label="Comments"
          ></v-textarea>
        </v-col>
      </v-row>
      <div class="d-flex align-center">
        <div class="mr-2 mt-n1 theme--light v-label">Access</div>
        <div>
          <v-radio-group v-model="formData.easeOfAccess" row style="font-size: 10px !important">
            <v-radio label="Easy" value="easy" class="ma-0 pa-0 mr-2"></v-radio>
            <v-radio label="Moderate" value="moderate" class="ma-0 pa-0 mr-2"></v-radio>
            <v-radio label="Hard" value="difficult" class="ma-0 pa-0"></v-radio>
          </v-radio-group>
        </div>
      </div>
      <div class="mt-3 d-flex">
        <div class="mr-2 mt-2 theme--light v-label">Visible on foot</div>
        <v-slider
          min="0"
          thumb-label="always"
          :thumb-size="formData.visibleOnFoot === 100 ? '28' : '24'"
          ticks
          max="100"
          step="10"
          v-model="formData.visibleOnFoot"
        >
          <template #thumb-label="{ value }"> {{ value }}% </template>
        </v-slider>
      </div>
      <v-alert type="error" v-if="error">
        {{ error }}
      </v-alert>
      <div class="text-center" v-if="!readonly">
        <FormAction @cancel="onCancel" @click="onSubmit" :loading="loading" :label="label" />
      </div>
    </v-form>
  </div>
</template>

<script>
import Land from '../../services/land';
import ruleMixin from '../../mixins/rules';
import Organization from '../../services/organization';
import { fullName, parseTeam, teamIncludes } from '../../utils';
import moment from 'moment';
const land = new Land();
export default {
  mixins: [ruleMixin],
  components: {
    FormAction: () => import('@/components/Feature/FormAction.vue'),
    DateField: () => import('@/components/Core/DateField.vue')
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    parcelId: {
      type: String,
      default: ''
    },
    activityId: {
      type: String,
      default: ''
    },
    update: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valid: true,
      error: '',
      loading: false,
      pageLoading: false,
      formData: {},
      types: [],
      userList: [],
      selectMenu: false
    };
  },
  computed: {
    schedule() {
      const res = {
        status: false,
        message: '',
        days: 0
      };
      if (this.formData.date) {
        const start = moment();
        const end = moment(this.formData.date);
        const diff = end.startOf('day').diff(start.startOf('day'), 'days');
        res.days = diff;
        if (diff > 0) {
          res.message = `${diff} day${diff > 1 ? 's' : ''}`;
          res.status = true;
        }
      }
      return res;
    },
    label() {
      if (this.update) {
        return 'Update';
      } else {
        return `Save ${
          this.formData.type && this.formData.type !== 'other' ? this.formData.type : ''
        }`;
      }
    }
  },
  created() {
    this.types = land.ACTIVITY_TYPES;
    this.getMembers();
  },
  mounted() {
    this.reset({ date: true });
    this.formData.type = 'monitoring';
  },
  methods: {
    showIssue(type) {
      return type && type !== 'issues';
    },
    updateIssueResolvedDate() {
      if (this.formData.issues) {
        this.formData.issuesResolvedAt = new Date();
      }
    },
    async getMembers() {
      const org = new Organization();
      const id = this.$store.state.loggedInAs.id;
      let members = [];

      let teams = [];
      const owner = [];
      const teamMembers = [];
      const otherMembers = [];

      const loggedInUser = fullName(this.$store.state.user.profile);

      try {
        const doc = await org.getPermissions(id);
        if (doc.exists) {
          const data = doc.data();
          teams.push(...parseTeam(data.team[this.$store.state.user.email]));
          data.users.forEach(user => {
            if (data.fullName && typeof data.fullName === 'object' && data.fullName[user]) {
              members.push({ name: data.fullName[user], teams: parseTeam(data.team[user]) });
            }
          });
        }
      } catch {}

      try {
        const doc = await org.retrieve(id);
        if (doc.exists) {
          const data = doc.data();
          if (data.userFullName) {
            owner.push(data.userFullName);
          }
        }
      } catch {}

      members.forEach(member => {
        if (teamIncludes(teams, member.teams)) {
          teamMembers.push(member);
        } else {
          otherMembers.push(member);
        }
      });

      teamMembers.sort((a, b) => a.name.localeCompare(b.name));
      otherMembers.sort((a, b) => a.name.localeCompare(b.name));

      const allMembers = [
        ...new Set([
          loggedInUser,
          ...owner,
          ...teamMembers.map(i => i.name),
          ...otherMembers.map(i => i.name)
        ])
      ];
      this.userList = allMembers;

      if (allMembers.length && !this.activityId) {
        this.formData.name = [allMembers[0]];
      }
    },
    defaultFormData({ date } = {}) {
      const res = {
        name: '',
        type: '',
        issues: null
      };
      if (date) {
        res.date = moment().format('YYYY-MM-DD');
      }
      return res;
    },
    submit() {},
    cancel() {
      const emptyForm = Object.keys(this.formData).every(key => {
        return !!!this.formData[key];
      });
      this.reset();
      if (emptyForm) {
        this.$router.go(-1);
      }
    },
    reset(args) {
      this.formData = this.defaultFormData(args);
      this.$refs?.form?.resetValidation();
    },
    onSubmit() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.submit();
    },
    onCancel() {
      this.cancel();
    }
  }
};
</script>

<style>
</style>
